import Vue from "vue";
import Router from "vue-router";

//****************************************//
// CHECK FOR PARENT AND CHILDREN ROUTES   //
// AlSO CHECK FOR REQUIRE AUTH            //
//****************************************//

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/Body.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          path: `/MailboxManagement`,
          name: "MailboxManagement",
          component: () => import("../views/ConversationFolder/MailboxManagement.vue"),
        },
        {
          path: `/feedback`,
          name: "feedback",
          component: () => import("../views/FeedBack.vue"),
        },
        {
          path: "/composed",
          name: "composed",
          component: () => import("../views/Composed.vue"),
        },
        {
          path: "/composedSample",
          name: "composedSample",
          component: () => import("../views/ComposedSample.vue"),
        },
        {
          path: `/category`,
          name: "category",
          component: () => import("../views/Category.vue"),
        },
        {
          path: `/mastermaintenance`,
          name: "mastermaintenance",
          component: () => import("../views/MasterMaintenance.vue"),
        },
        {
          path: `/correction`,
          name: "correction",
          // component: () => import("../views/underMaintainance.vue"),
          component: () => import("../views/CorrectionRequest.vue"),
        },
        {
          path: `/undermaintenance`,
          name: "undermaintenance",
          component: () => import("../views/underMaintainance.vue"),
          // component: () => import("../views/CorrectionRequest.vue"),
        },
        {
          path: `/adminUser`,
          name: "adminUser",
          component: () => import("../views/adminUser.vue"),
        },
        // {
        //   path: `/template`,
        //   name: "template",
        //   component: () => import("../views/UserTemplate.vue"),
        // },
        {
          path: `/timeline`,
          name: "timeline",
          component: () => import("../components/Timeline/Timeline.vue"),
        },
        {
          path: "/Draft",
          name: "Draft",
          component: () => import("../views/Drafts.vue"),
          children: [
            {
              path: "/Draft/DraftContent/:id",
              name: "DraftContent",
              component: () => import("../views/DraftContent.vue"),
            },
              {
                path: "/Draft/DraftConversation/:id/:draftid",
                name: "DraftConversation",
                component: () => import("../views/DraftConversation.vue"),
              },
          ],
        },
        {
          path: "/inbox",
          name: "inbox",
          component: () => import("../views/ConversationFolder/Inbox.vue"),
          children: [
            {
              path: "/inbox/reply/:id",
              name: "reply",
              component: () => import("../views/ConversationFolder/Reply.vue"),
            },
          ],
        },
        {
          path: "/received",
          name: "received",
          component: () =>
            import("../views/ConversationFolder/ReceivedInbox.vue"),
          children: [
            {
              path: "/received/reply/:id",
              name: "receivedReply",
              component: () =>
                import("../views/ConversationFolder/ReceivedReply.vue"),
            },
          ],
        },
        {
          path: "/subhandle",
          name: "subhandle",
          component: () =>
            import("../views/ConversationFolder/SubHandleInbox.vue"),
          children: [
            {
              path: "/subhandle/reply/:id",
              name: "SubReply",
              component: () =>
                import("../views/ConversationFolder/SubHandleReply.vue"),
            },
          ],
        },
        // {
        //   path: "/AllEmails",
        //   name: "AllEmails",
        //   component: () =>
        //     import("../views/ConversationFolder/AllEmails.vue"),
        //   children: [
        //     {
        //       path: "/AllEmails/AllEmailsReply/:id",
        //       name: "AllEmailsReply",
        //       component: () =>
        //         import("../views/ConversationFolder/AllEmailsReply.vue"),
        //     },
        //   ],
        // },
        {
          path: "/AllInboxDepartment",
          name: "AllInboxDepartment",
          component: () =>
            import("../views/ConversationFolder/AllInboxDepartment.vue"),
          children: [
            {
              path: "/AllInboxDepartment/AllInboxDepartmentReply/:id",
              name: "AllInboxDepartmentReply",
              component: () =>
                import("../views/ConversationFolder/AllInboxDepartmentReply.vue"),
            },
          ],
        },
        {
          path: `/extraction`,
          name: "extraction",
          component: () => import("../views/ConversationFolder/DataExtraction.vue"),
        },
        {
          path: `/InquirySummary`,
          name: "InquirySummary",
          component: () => import("../views/ConversationFolder/InquirySummary.vue"),
        },
        {
          path: `/InquirySummaryAllDeparment`,
          name: "InquirySummaryAllDeparment",
          component: () => import("../views/ConversationFolder/InquirySummaryAllDeparment.vue"),
        },
        // {
        //   path: `/outbox`,
        //   name: "outbox",
        //   component: () => import("../views/ConversationFolder/Outbox.vue"),
        //   children:[
        //     {
        //       path: "/outbox/OutboxReply/:id",
        //       name: "OutboxReply",
        //       component: () =>
        //         import("../views/ConversationFolder/OutboxReply.vue"),
        //     }
        //   ]
        // }
      ],
    },
    {
      path: `/login`,
      name: "login",
      component: () => import("../views/Login.vue"),
    },    
    // //!FOR UNDERMAINTENANCE
    // {
    //   path: `/login`,
    //   name: "login",
    //   component: () => import("../views/underMaintainance.vue"),
    // },
    // //!FOR UNDERMAINTENANCE
    {
      path: "*",
      name: "wrongroutes",
      component: () => import("../views/WrongRoutes.vue"),
    },
  ],
});
Vue.use(Router);
import store from "@/store";

//**********************************************//
// CHECKING AND VALIDATION IN ROUTER CHANGING   //
//**********************************************//

router.beforeEach((to, from, next) => {
  // PASS STORE DATA FROM VUEX
  let storageCheck = store.state.userinfo.FullName;
  const checkPath = to.path.split("/");

  // CHECKING IF USER PRESS F5 IN ALL CHECKPATH[2] VALUE
  if (
    checkPath[1] + "/" + checkPath[2] == "AllEmailsDepartment/AllEmailsDepartmentReply" &&
    from.path == "/"
  )
    router.replace(`/AllEmailsDepartment`);
  if (
    checkPath[1] + "/" + checkPath[2] == "Draft/DraftContent" &&
    from.path == "/"
  )
    router.replace(`/Draft`);

  if (checkPath[1] + "/" + checkPath[2] == "inbox/reply" && from.path == "/")
    router.replace(`/inbox`);

    if (checkPath[1] + "/" + checkPath[2] == "AllEmails/reply" && from.path == "/")
    router.replace(`/AllEmails`);

  if (to.path == "/") router.replace(`/composed`);
  if (to.path == "/" && store.state.userinfo.Department)
    next({ name: "correction" });

  // AS OF NOW FOR COMMENT SINCE WE NEED TO AUTO GET CONFIRMATION URL
  // FROM WINDOWS LIVE MAIL

  /*   if (
    checkPath[1] + "/" + checkPath[2] == "received/reply" &&
    from.path == "/"
  ) {
    router.replace(`/received`);
  } */

  // CHECK IF THE ROUTES HAVE AUTH
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //CHECK IF THE USER IS AUTHENTICATED
    if (storageCheck) next();
    // USER IS NOT AUTHENTICATED
    else router.replace(`/login`);

    next();
  } else {
    if (storageCheck) {
      localStorage.clear();
      next();
    } else next();
  }
});

export default router;
